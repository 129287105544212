<template>
  <div class="page" id="SlideUp">
    <h1>SlideUp</h1>
  </div>
</template>

<script>
export default {
  name: "SlideUp",
}
</script>

<style scoped>

</style>
